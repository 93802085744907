import CompanyProfile from "../../Components/Body/CompanyProfile/CompanyProfile"
import FormSubmit from "../../Components/Body/FormSubmit/FormSubmit"
import Slider from "../../Components/Body/Slider/Slider"
import Welcome from "../../Components/Body/Welcome/Welcome"
import Footer from "../../Components/Footer/Footer"
import Navbar from "../../Components/Navbar/Navbar"
import Testimonial from "../../Components/Body/Testimonial/Testimonial"
import React, { useState } from "react"
import ProjectProfile from "../../Components/Body/ProjectProfile/ProjectProfile"
import { lgpremiumimages } from "../../Components/Body/ProjectProfile/images"
import { saivigneshwaraimages } from "../../Components/Body/ProjectProfile/images"
import { greenmedimages } from "../../Components/Body/ProjectProfile/images"
import { lotus3images } from "../../Components/Body/ProjectProfile/images"
import { lotus4images } from "../../Components/Body/ProjectProfile/images"
import lotus from "../../assets/lotusgarden.png"
import greenmedlogo from "../../assets/greenmedlogo.png"
import vigneshlogo from "../../assets/vigneshlogo.png"
import { background } from "@chakra-ui/react"
import "./Home.css"
import Whatsapp from "../../Components/Body/Whatsapp/Whatsapp"

import { MdLocalAirport } from "react-icons/md"
import { GiRoad } from "react-icons/gi"
import { GiColombianStatue } from "react-icons/gi"
import { TfiMicrosoftAlt } from "react-icons/tfi"
import { FaUniversity } from "react-icons/fa"
import { FaIndustry } from "react-icons/fa"
import { GiFruitTree } from "react-icons/gi"

import lotusbrochure from "../../assets/pdf/lotus.pdf"
import vigneshwarabrochure from "../../assets/pdf/vigneshwara.pdf"

const Home = () => {
  const [show, setShow] = useState(false)

  function toggle() {
    setShow((prev) => !prev)
  }

  return (
    <div className="App">
      <Whatsapp />
      <Navbar show={show} setShow={setShow} toggle={toggle} />
      <div>
        <div className="cta">
          <Welcome />
          <Slider />
        </div>
        <CompanyProfile />
        <ProjectProfile
          brochure={lotusbrochure}
          name="LG PREMIUM"
          detail="Lotus Garden Premium is a well designed layout with vaastu
            compliant and all the required facilities as per HMDA."
          head="true"
          logo={lotus}
          images={lgpremiumimages}
          status={true}
          link="/lotuspremium"
          location="Chattanpally Village, Shadnagar"
          size="167 to 881 sq.yds"
          amen={
            <>
              <div className="amenities-top">
                <div className="hbold left">
                  <GiRoad size={30} />
                  <h2>Close to NH-44</h2>
                </div>
                <div className="hbold right">
                  <MdLocalAirport size={30} />
                  <h2>Near to International Airport</h2>
                </div>
              </div>
              <div className="amenities-bottom">
                <div className="hbold left">
                  <GiColombianStatue size={30} />
                  <h2>Vicinity of Statue of Equality</h2>
                </div>
                <div className="hbold right">
                  <TfiMicrosoftAlt size={30} />
                  <h2>Near Microsoft Data center</h2>
                </div>
              </div>
            </>
          }
        />
        <ProjectProfile
          name="Green Meadows"
          detail="Experience the perfect blend of nature and modernity with Green Meadows, a thoughtfully planned plot venture designed to cater to your every need."
          images={greenmedimages}
          logo={greenmedlogo}
          style={{ backgroundColor: "white" }}
          status={true}
          link="/greenmedows"
          location="Chegunta"
          size="167 to 400 sq.yds"
          amen={
            <>
              <div className="amenities-top">
                <div className="hbold left">
                  <GiRoad size={30} />
                  <h2>Close to NH-44</h2>
                </div>
                <div className="hbold right">
                  <MdLocalAirport size={30} />
                  <h2>Near to International Airport</h2>
                </div>
              </div>
              <div className="amenities-bottom">
                <div className="hbold left">
                  <GiColombianStatue size={30} />
                  <h2>Vicinity of Statue of Equality</h2>
                </div>
                <div className="hbold right">
                  <TfiMicrosoftAlt size={30} />
                  <h2>Near Microsoft Data center</h2>
                </div>
              </div>
            </>
          }
        />
        <ProjectProfile
          brochure={lotusbrochure}
          name="Lotus Garden - 3"
          detail="Enter into a new Horizon of promises. Lotus Gardens-3 is a well designed layout with vaastu
          compliant and all the required facilities as per HMDA."
          images={lotus3images}
          logo={lotus}
          status={false}
          link="/lotus3"
          location="Chattanpally Village, Shadnagar"
          size="167 to 400 sq.yds"
          amen={
            <>
              <div className="amenities-top">
                <div className="hbold left">
                  <GiRoad size={30} />
                  <h2>Close to NH-44</h2>
                </div>
                <div className="hbold right">
                  <MdLocalAirport size={30} />
                  <h2>Near to International Airport</h2>
                </div>
              </div>
              <div className="amenities-bottom">
                <div className="hbold left">
                  <GiColombianStatue size={30} />
                  <h2>Vicinity of Statue of Equality</h2>
                </div>
                <div className="hbold right">
                  <TfiMicrosoftAlt size={30} />
                  <h2>Near Microsoft Data center</h2>
                </div>
              </div>
            </>
          }
        />
        <ProjectProfile
          brochure={lotusbrochure}
          name="Lotus Garden - 4"
          detail="Discover a world of tranquility and beauty with Lotus Garden, an exquisite plot venture that blends modern amenities with serene natural surroundings."
          images={lotus4images}
          logo={lotus}
          style={{ backgroundColor: "white" }}
          status={false}
          link="/lotus4"
          location="Chattanpally Village, Shadnagar"
          size="167 to 400 sq.yds"
          amen={
            <>
              <div className="amenities-top">
                <div className="hbold left">
                  <GiRoad size={30} />
                  <h2>Close to NH-44</h2>
                </div>
                <div className="hbold right">
                  <MdLocalAirport size={30} />
                  <h2>Near to International Airport</h2>
                </div>
              </div>
              <div className="amenities-bottom">
                <div className="hbold left">
                  <GiColombianStatue size={30} />
                  <h2>Vicinity of Statue of Equality</h2>
                </div>
                <div className="hbold right">
                  <TfiMicrosoftAlt size={30} />
                  <h2>Near Microsoft Data center</h2>
                </div>
              </div>
            </>
          }
        />
        <ProjectProfile
          brochure={vigneshwarabrochure}
          name="Sai Vigneshwara"
          detail="Embrace the tranquility of Urban living with this expansive plot of land, perfect for those seeking a peaceful retreat from the hustle and bustle of city life."
          images={saivigneshwaraimages}
          logo={vigneshlogo}
          status={false}
          link="/vigneshwara"
          location="Lakdaram"
          size="167 to 400 sq.yds"
          amen={
            <>
              <div className="amenities-top">
                <div className="hbold left">
                  <GiRoad size={30} />
                  <h2>Close to NH-Mumbai</h2>
                </div>
                <div className="hbold right">
                  <FaIndustry size={30} />
                  <h2>Near to Kollur IT SEZ</h2>
                </div>
              </div>
              <div className="amenities-bottom">
                <div className="hbold left">
                  <FaUniversity size={30} />
                  <h2>GITAM University & IIT Kandi</h2>
                </div>
                <div className="hbold right">
                  <GiFruitTree size={30} />
                  <h2>Pleasant Atmosphere</h2>
                </div>
              </div>
            </>
          }
        />
        <Testimonial />
        <FormSubmit left={true} title="SCHEDULE YOUR SITE VISIT" />
        <Footer />
      </div>
    </div>
  )
}

export default Home
