import React, { useEffect } from "react"
import { Carousel } from "react-responsive-carousel"
import Whatsapp from "../../Components/Body/Whatsapp/Whatsapp"
import Footer from "../../Components/Footer/Footer"
import Navbar from "../../Components/Navbar/Navbar"
import { lotus3images } from "../../Components/Body/ProjectProfile/images"
import { lotus4images } from "../../Components/Body/ProjectProfile/images"
import { saivigneshwaraimages } from "../../Components/Body/ProjectProfile/images"
import { lgpremiumimages } from "../../Components/Body/ProjectProfile/images"
import { greenmedimages } from "../../Components/Body/ProjectProfile/images"
import lglogo from "../../assets/lotusgarden.png"
import vigneshlogo from "../../assets/vigneshlogo.png"
import greenmedlogo from "../../assets/greenmedlogo.png"
import "./Gallery.scss"
import { IoIosArrowForward } from "react-icons/io"
import { NavLink } from "react-router-dom"

const Gallery = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Navbar />
      <Whatsapp />
      <div className="ongoing">
        <h1 className="title">Gallery</h1>
        <hr></hr>

        <div className="ongoinglist">
          <div className="cards">
            <div className="cardWrapper">
              <Carousel
                autoPlay={true}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                showArrows={false}
                infiniteLoop={true}
                dynamicHeight={true}
                stopOnHover={false}
              >
                {lgpremiumimages.map((img) => {
                  return (
                    <div key={img}>
                      <img src={img} className="img1" alt="plot" />
                    </div>
                  )
                })}
              </Carousel>
              <NavLink to="/lgprmgallery" target="_blank">
                <img src={lglogo} className="ongoinglogo" />
                <button className="projectButton">
                  LG Premium <IoIosArrowForward size={10} />
                </button>
              </NavLink>
            </div>
            <div className="cardWrapper">
              <Carousel
                autoPlay={true}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                showArrows={false}
                infiniteLoop={true}
                dynamicHeight={true}
                stopOnHover={false}
              >
                {greenmedimages.map((img) => {
                  return (
                    <div key={img}>
                      <img src={img} className="img1" alt="plot" />
                    </div>
                  )
                })}
              </Carousel>
              <NavLink to="/greenmedgallery" target="_blank">
                <img src={greenmedlogo} className="ongoinglogo" />
                <button className="projectButton">
                  Green Medows <IoIosArrowForward size={10} />
                </button>
              </NavLink>
            </div>
            <div className="cardWrapper">
              <Carousel
                autoPlay={true}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                showArrows={false}
                infiniteLoop={true}
                dynamicHeight={true}
                stopOnHover={false}
              >
                {saivigneshwaraimages.map((img) => {
                  return (
                    <div key={img}>
                      <img src={img} className="img1" alt="plot" />
                    </div>
                  )
                })}
              </Carousel>
              <NavLink to="/vigneshwaragallery" target="_blank">
                <img src={vigneshlogo} className="ongoinglogo" />
                <button className="projectButton">
                  Sai Vigneshwara <IoIosArrowForward size={10} />
                </button>
              </NavLink>
            </div>
            <div className="cardWrapper">
              <Carousel
                autoPlay={true}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                showArrows={false}
                infiniteLoop={true}
                dynamicHeight={true}
                stopOnHover={false}
              >
                {lotus3images.map((img) => {
                  return (
                    <div key={img}>
                      <img src={img} className="img1" alt="plot" />
                    </div>
                  )
                })}
              </Carousel>
              <NavLink to="/lotus3gallery" target="_blank">
                <img src={lglogo} className="ongoinglogo" />
                <button className="projectButton">
                  Lotus Garden 3 <IoIosArrowForward size={10} />
                </button>
              </NavLink>
            </div>
            <div className="cardWrapper">
              <Carousel
                autoPlay={true}
                showStatus={false}
                showIndicators={false}
                showThumbs={false}
                showArrows={false}
                infiniteLoop={true}
                dynamicHeight={true}
                stopOnHover={false}
              >
                {lotus4images.map((img) => {
                  return (
                    <div key={img}>
                      <img src={img} className="img1" alt="plot" />
                    </div>
                  )
                })}
              </Carousel>
              <NavLink to="/lotus4gallery" target="_blank">
                <img src={lglogo} className="ongoinglogo" />
                <button className="projectButton">
                  Lotus Garden 4 <IoIosArrowForward size={10} />
                </button>
              </NavLink>
            </div>
          </div>
        </div>

        <div className="mobgal">
          <NavLink to="/lgprmgallery">
            <div className="carddd">
              <h1>LG PREMIUM</h1>
              <img src={lgpremiumimages[0]} alt="plottt" />
            </div>
          </NavLink>
          <NavLink to="/greenmedgallery">
            <div className="carddd">
              <h1>Green Meadows</h1>
              <img src={greenmedimages[0]} alt="plottt" />
            </div>
          </NavLink>

          <NavLink to="/lotus3gallery">
            <div className="carddd">
              <h1>LG 3</h1>

              <img src={lotus3images[0]} alt="plottt" />
            </div>
          </NavLink>

          <NavLink to="/lotus4gallery">
            <div className="carddd">
              <h1>LG 4</h1>

              <img src={lotus4images[0]} alt="plottt" />
            </div>
          </NavLink>

          <NavLink to="/vigneshwaragallery">
            <div className="carddd">
              <h1>Sai Vigneshwara</h1>
              <img src={saivigneshwaraimages[3]} alt="plottt" />
            </div>
          </NavLink>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Gallery
