import React from "react"
import { Carousel } from "react-responsive-carousel"
import Whatsapp from "../../../Components/Body/Whatsapp/Whatsapp"
import Footer from "../../../Components/Footer/Footer"
import Navbar from "../../../Components/Navbar/Navbar"
import {lotus3images} from "../../../Components/Body/ProjectProfile/images"
import {lotus4images} from "../../../Components/Body/ProjectProfile/images"
import {saivigneshwaraimages} from "../../../Components/Body/ProjectProfile/images"
import lglogo from "../../../assets/lotusgarden.png"
import vigneshlogo from "../../../assets/vigneshlogo.png"
import "./Completed.scss"
import { IoIosArrowForward } from "react-icons/io"
import { NavLink } from "react-router-dom"


const Completed = () => {
  return (
    <div>
    <Navbar></Navbar>
    <Whatsapp />
    <div className="ongoing">
    <h1 className="title">COMPLETED PROJECTS</h1>
        <hr></hr>
      <div className="ongoinglist">
        <div className="cards">
          <div className="cardWrapper">
          <Carousel
            autoPlay={true}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            showArrows={false}
            infiniteLoop={true}
            dynamicHeight={true}
            stopOnHover={false}
          >
            {lotus3images.map((img) => {
              return (
                <div key={img}>
                  <img src={img} className="img1" alt="plot" />
                </div>
              )
            })}
          </Carousel>
          <NavLink to="/lotus3" target="_blank">
          <img src={lglogo} className="ongoinglogo" />
          <button className="projectButton">
            Lotus Garden 3 <IoIosArrowForward size={10} />
          </button>
          </NavLink>
          </div>
          <div className="cardWrapper">
          <Carousel
            autoPlay={true}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            showArrows={false}
            infiniteLoop={true}
            dynamicHeight={true}
            stopOnHover={false}
          >
            {lotus4images.map((img) => {
              return (
                <div key={img}>
                  <img src={img} className="img1" alt="plot" />
                </div>
              )
            })}
          </Carousel>
          <NavLink to="/lotus4" target="_blank">
          <img src={lglogo} className="ongoinglogo" />
          <button className="projectButton">
            Lotus Garden 4 <IoIosArrowForward size={10} />
          </button>
          </NavLink>
          </div>
          <div className="cardWrapper">
          <Carousel
            autoPlay={true}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            showArrows={false}
            infiniteLoop={true}
            dynamicHeight={true}
            stopOnHover={false}
          >
            {saivigneshwaraimages.map((img) => {
              return (
                <div key={img}>
                  <img src={img} className="img1" alt="plot" />
                </div>
              )
            })}
          </Carousel>
          <NavLink to="/vigneshwara" target="_blank">
          <img src={vigneshlogo} className="ongoinglogo" />
          <button className="projectButton">
            Sai Vigneshwara <IoIosArrowForward size={10} />
          </button>
          </NavLink>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
  )
}

export default Completed