import React from "react"
import { Carousel } from "react-responsive-carousel"
import Whatsapp from "../../../Components/Body/Whatsapp/Whatsapp"
import Footer from "../../../Components/Footer/Footer"
import Navbar from "../../../Components/Navbar/Navbar"
import {lgpremiumimages} from "../../../Components/Body/ProjectProfile/images"
import {greenmedimages} from "../../../Components/Body/ProjectProfile/images"
import lglogo from "../../../assets/lotusgarden.png"
import greenmedlogo from "../../../assets/greenmedlogo.png"
import "./Ongoing.scss"
import { IoIosArrowForward } from "react-icons/io"
import { NavLink } from "react-router-dom" 

const Ongoing = () => {
  return (
    <div>
      <Navbar></Navbar>
      <Whatsapp />
      <div className="ongoing">
      <h1 className="title">ONGOING PROJECTS</h1>
          <hr></hr>
        <div className="ongoinglist">
          <div className="cards">
            <div className="cardWrapper">
            <Carousel
              autoPlay={true}
              showStatus={false}
              showIndicators={false}
              showThumbs={false}
              showArrows={false}
              infiniteLoop={true}
              dynamicHeight={true}
              stopOnHover={false}
            >
              {lgpremiumimages.map((img) => {
                return (
                  <div key={img}>
                    <img src={img} className="img1" alt="plot" />
                  </div>
                )
              })}
            </Carousel>
            <NavLink to="/lotuspremium" target="_blank">
            <img src={lglogo} className="ongoinglogo" />
            <button className="projectButton">
              Know More <IoIosArrowForward size={10} />
            </button>
            </NavLink>
            </div>
            <div className="cardWrapper">
            <Carousel
              autoPlay={true}
              showStatus={false}
              showIndicators={false}
              showThumbs={false}
              showArrows={false}
              infiniteLoop={true}
              dynamicHeight={true}
              stopOnHover={false}
            >
              {greenmedimages.map((img) => {
                return (
                  <div key={img}>
                    <img src={img} className="img1" alt="plot" />
                  </div>
                )
              })}
            </Carousel>
            <NavLink to="/greenmedows" target="_blank">
            <img src={greenmedlogo} className="ongoinglogo" />
            <button className="projectButton">
              Know More <IoIosArrowForward size={10} />
            </button>
            </NavLink>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Ongoing
