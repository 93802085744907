import React from "react"
import Imageslider from "../../../Components/Imageslider/Imageslider"
import Navbar from "../../../Components/Navbar/Navbar"
import { lotus3images } from "../../../Components/Body/ProjectProfile/images"
import Footer from "../../../Components/Footer/Footer"
import Whatsapp from "../../../Components/Body/Whatsapp/Whatsapp"

const Lotus3gallery = () => {
  return (
    <>
      <Navbar />
      <Whatsapp />

      <div className="ongoing">
        <h1 className="title">Lotus Garden 3</h1>
        <hr></hr>
        <Imageslider images={[...lotus3images]} />
      </div>
      <Footer />

    </>
  )
}

export default Lotus3gallery
