import image from "../../../assets/msrPlotImg1.jpeg"
import image1 from "../../../assets/msrPlotImg2.jpeg"
import image2 from "../../../assets/msrPlotImg3.jpeg"
import image4 from "../../../assets/plot1 (1).jpeg"
import image5 from "../../../assets/plot1 (2).jpeg"
import image6 from "../../../assets/plot1 (3).jpeg"
import image7 from "../../../assets/plot1 (4).jpeg"
import image8 from "../../../assets/plot1 (5).jpeg"
import image9 from "../../../assets/plot1 (6).jpeg"
import image10 from "../../../assets/plot1 (7).jpeg"

import a from "../../../assets/projects/saivigneshwara/1.jpg"
import b from "../../../assets/projects/saivigneshwara/2.jpg"
import c from "../../../assets/projects/saivigneshwara/3.jpg"
import d from "../../../assets/projects/saivigneshwara/4.jpg"
import e from "../../../assets/projects/saivigneshwara/5.jpg"
import f from "../../../assets/projects/saivigneshwara/6.jpg"
import g from "../../../assets/projects/saivigneshwara/7.jpg"
import h from "../../../assets/projects/saivigneshwara/8.jpg"
import i from "../../../assets/projects/saivigneshwara/9.jpg"

import ga from "../../../assets/projects/greenmedows/1.jpg"
import gb from "../../../assets/projects/greenmedows/2.jpg"
import gc from "../../../assets/projects/greenmedows/3.jpg"
import gd from "../../../assets/projects/greenmedows/4.jpg"
import ge from "../../../assets/projects/greenmedows/5.jpg"
import gf from "../../../assets/projects/greenmedows/6.jpg"
import gg from "../../../assets/projects/greenmedows/7.jpg"
import gh from "../../../assets/projects/greenmedows/8.jpg"
import gi from "../../../assets/projects/greenmedows/9.jpg"
import gj from "../../../assets/projects/greenmedows/10.jpg"
import gk from "../../../assets/projects/greenmedows/11.jpg"
import gl from "../../../assets/projects/greenmedows/12.jpg"
import gm from "../../../assets/projects/greenmedows/13.jpg"
import gn from "../../../assets/projects/greenmedows/14.jpg"
import go from "../../../assets/projects/greenmedows/15.jpg"
import gp from "../../../assets/projects/greenmedows/16.jpg"
import gq from "../../../assets/projects/greenmedows/17.jpg"
import gr from "../../../assets/projects/greenmedows/18.jpg"
import gs from "../../../assets/projects/greenmedows/19.jpg"
import gt from "../../../assets/projects/greenmedows/20.jpg"
import gu from "../../../assets/projects/greenmedows/21.jpg"

import lga from "../../../assets/projects/lotusgarden3/1.jpg"
import lgb from "../../../assets/projects/lotusgarden3/2.jpg"
import lgc from "../../../assets/projects/lotusgarden3/3.jpg"
import lgd from "../../../assets/projects/lotusgarden3/4.jpg"
import lge from "../../../assets/projects/lotusgarden3/5.jpg"
import lgf from "../../../assets/projects/lotusgarden3/6.jpg"
import lgg from "../../../assets/projects/lotusgarden3/7.jpg"
import lgh from "../../../assets/projects/lotusgarden3/8.jpg"
import lgi from "../../../assets/projects/lotusgarden3/9.jpg"
import lgj from "../../../assets/projects/lotusgarden3/10.jpg"

import llga from "../../../assets/projects/lotusgarden4/1.jpg"
import llgb from "../../../assets/projects/lotusgarden4/2.jpg"
import llgc from "../../../assets/projects/lotusgarden4/3.jpg"
import llgd from "../../../assets/projects/lotusgarden4/4.jpg"
import llge from "../../../assets/projects/lotusgarden4/5.jpg"
import llgf from "../../../assets/projects/lotusgarden4/6.jpg"
import llgg from "../../../assets/projects/lotusgarden4/7.jpg"
import llgh from "../../../assets/projects/lotusgarden4/8.jpg"
import llgi from "../../../assets/projects/lotusgarden4/9.jpg"
import llgj from "../../../assets/projects/lotusgarden4/10.jpg"
import llgk from "../../../assets/projects/lotusgarden4/11.jpg"
import llgl from "../../../assets/projects/lotusgarden4/12.jpg"
import llgm from "../../../assets/projects/lotusgarden4/13.jpg"

export const lgpremiumimages = [
  image,
  image1,
  image2,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
]

export const saivigneshwaraimages = [a, b, c, d, e, f, g, h, i]

export const greenmedimages = [
  ga,
  gb,
  gc,
  gd,
  ge,
  gf,
  gg,
  gh,
  gi,
  gj,
  gk,
  gl,
  gm,
  gn,
  go,
  gp,
  gq,
  gr,
  gs,
  gt,
  gu,
]

export const lotus3images = [lga, lgb, lgc, lgd, lge, lgf, lgg, lgh, lgi, lgj]
export const lotus4images = [
  llga,
  llgb,
  llgc,
  llgd,
  llge,
  llgf,
  llgg,
  llgh,
  llgi,
  llgj,
  llgk,
  llgl,
  llgm,
]
