import React, { useEffect } from "react"
import Iframe from "react-iframe"
import Footer from "../../../Components/Footer/Footer"
import Navbar from "../../../Components/Navbar/Navbar"
import "./Lotus4.scss"

import lotus from "../../../assets/lotusgarden.png"
import plot from "../../../assets/projects/lotusgarden4/13.jpg"
import { GrLocation } from "react-icons/gr"

import roads from "../../../assets/roads.png"
import vastu from "../../../assets/vastu.png"
import lights from "../../../assets/lights.png"
import security from "../../../assets/watertank.png"
import water from "../../../assets/watertank.png"
import play from "../../../assets/playground.png"
import drinage from "../../../assets/drinage.png"
import electricity from "../../../assets/electricity.png"
import FormSubmit from "../../../Components/Body/FormSubmit/FormSubmit"

import image from "../../../assets/projects/lotusgarden4/1.jpg"
import image1 from "../../../assets/projects/lotusgarden4/2.jpg"
import image2 from "../../../assets/projects/lotusgarden4/3.jpg"
import image3 from "../../../assets/projects/lotusgarden4/4.jpg"
import image4 from "../../../assets/projects/lotusgarden4/5.jpg"
import image5 from "../../../assets/projects/lotusgarden4/6.jpg"
import image6 from "../../../assets/projects/lotusgarden4/7.jpg"
import image7 from "../../../assets/projects/lotusgarden4/8.jpg"
import image8 from "../../../assets/projects/lotusgarden4/9.jpg"
import image9 from "../../../assets/plot1 (6).jpeg"
import image10 from "../../../assets/plot1 (7).jpeg"
import Whatsapp from "../../../Components/Body/Whatsapp/Whatsapp"
import Imageslider from "../../../Components/Imageslider/Imageslider"

const Projects = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Navbar />
      <Whatsapp />
      <div className="projectFull">
        <div className="projectIntro">
          <img src={lotus} alt="project logo" className="plotlogo1" />
          <img src={plot} alt="project logo" className="plotimg1" />
        </div>
        <div className="projectInfo1">
          <div className="left">
            <img src={lotus} alt="lotus" />
          </div>
          <div className="right">
            <h1>Lotus Garden 4</h1>
            <p>
              <GrLocation /> Chattanpally Village, Shadnagar
            </p>
            <p>
              Mana Sampadha Realtors are launching a new venture "Lotus Garden
              4" at Chattanpally Village, Shadnagar after successful completion
              of thier maiden venture Vedika phase - 1 & 2 and Lotus Gardens -
              1,2,3 & 4 all are final approved layouts.
            </p>
            <p>
              Lotus Gardens - Premium directly connects to NH 44, the busiest
              highway between Hyderabad & Bengaluru. It is 30 minutes drive from
              the city towards NH-44 and close to the 6 track by-pass road at
              Chattanpally, Shadnagar Municipality, 20 minutes drive from
              International Airport, Outer Ring Road, Fab city, My Home Mega
              Project, statue of Equality project, JIIMS Swarna Bharati Trust,
              P&G, Johnson & Johnson, Amazon and adjacent to DLF township and
              Gayatri constructions.
            </p>
            <p>
              Lotus Gardens-Premium is a well designed layout with vaastu
              compliant, with plots in sizes of 167 to 881 sq.yds., with 100'
              ft, 40' ft, 30' ft cement concrete roads and all the required
              facilities as per HMDA.
            </p>
          </div>
          {/* <div className="projectDetail">
            <div></div>
          </div> */}
        </div>

        <div className="salient1">
          <h1>Salient Features</h1>
          <div className="salienticons1">
            <div className="icon">
              <img src={roads} alt="road" />
              <h4>CEMENT CONCRETE ROADS</h4>
            </div>
            <div className="icon">
              <img src={vastu} alt="vastu" />
              <h4>100% VASTHU</h4>
            </div>
            <div className="icon">
              <img src={lights} alt="road" />
              <h4>STREET LIGHTING</h4>
            </div>
            <div className="icon">
              <img src={security} alt="road" />
              <h4>24/7 SECURITY</h4>
            </div>
          </div>
          <div className="salienticons1">
            <div className="icon">
              <img src={water} alt="road" />
              <h4>DEDICATEED WATER TANK</h4>
            </div>
            <div className="icon">
              <img src={electricity} alt="road" />
              <h4>ELECTRICITY</h4>
            </div>
            <div className="icon">
              <img src={play} alt="road" />
              <h4>CHILDREN'S PLAY AREA</h4>
            </div>
            <div className="icon">
              <img src={drinage} alt="road" />
              <h4>DRAINAGE</h4>
            </div>
          </div>
        </div>
        <div className="map">
          <Iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18141.36573841015!2d78.25765602149082!3d17.084761973095887!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcbc9b17b640e83%3A0xfcc458e69f24416f!2sLotus%20Gardens!5e0!3m2!1sen!2sin!4v1678859158743!5m2!1sen!2sin"
            style="border:0;"
            width="100%"
            height="400px"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></Iframe>
        </div>

        <Imageslider images={[image, image1, image2, image3, image4, image5]} />
      </div>
      <FormSubmit />
      <Footer />
    </div>
  )
}

export default Projects
