import React from "react"
import "./ProjectProfile.scss"
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react"
import { Carousel } from "react-responsive-carousel"
import styles from "react-responsive-carousel/lib/styles/carousel.min.css"
import { AiFillHome } from "react-icons/ai"
import { FaBuilding } from "react-icons/fa"
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io"
import { Link, NavLink } from "react-router-dom"

const ProjectProfile = (props) => {
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch(props.brochure).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob)
        // Setting various property values
        let alink = document.createElement("a")
        alink.href = fileURL
        alink.download = "Brochure.pdf"
        alink.click()
      })
    })
  }

  return (
    <div className="projectProfile" style={props.style}>
      {props.head && (
        <>
          <h1 className="title">PROJECTS ON SALE</h1>
          <hr></hr>
        </>
      )}
      <div className="profile">
        <div className="left">
          <div className="slider">
            <div className="projectlogo">
              <img src={props.logo} alt="lotus garden"></img>
            </div>
            <Carousel
              autoPlay={true}
              showStatus={false}
              showIndicators={false}
              showThumbs={false}
              showArrows={true}
              infiniteLoop={true}
              dynamicHeight={true}
              stopOnHover={true}
            >
              {props.images.map((img) => {
                return (
                  <div key={img}>
                    <img src={img} className="img1" alt="plot" />
                  </div>
                )
              })}
            </Carousel>
          </div>
        </div>
        <div className="right">
          <h2>{props.name}</h2>
          <p className="ptext">{props.detail}</p>
          <Tabs size="lg" isFitted variant="line">
            <TabList mb="1em">
              <Tab
                _selected={{ color: "#00233d", bg: "#facd39" }}
                _hover={{ bg: "#facd39" }}
              >
                <AiFillHome />
                <span className="tabFont">Project Overview</span>
              </Tab>
              <Tab
                _selected={{ color: "#00233d", bg: "#facd39" }}
                _hover={{ bg: "#facd39" }}
              >
                <FaBuilding />
                <span className="tabFont">Amenities</span>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <p className="pbold">
                  <span className="bold">Status:</span>{" "}
                  {props.status ? "Ongoing Project" : "Completed Project"}
                </p>
                <p className="pbold">
                  <span className="bold">Location:</span> {props.location}
                </p>
                <p className="pbold">
                  <span className="bold">Plot sizes:</span> {props.size}
                </p>
                <p className="pbold">
                  <span className="bold">Roads:</span> 100'ft, 40'ft, 30'ft
                </p>
              </TabPanel>
              <TabPanel>{props.amen}</TabPanel>
            </TabPanels>
          </Tabs>
          <NavLink to={props.link} target="_blank">
            <button className="projectButton">
              Know More <IoIosArrowForward size={10} />
            </button>
          </NavLink>
          {props.brochure && (
            <button className="downloadButton" onClick={onButtonClick}>
              Brochure <IoIosArrowDown size={10} />
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProjectProfile
