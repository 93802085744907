import React, { useEffect } from "react"
import { BsFillTelephoneFill } from "react-icons/bs"
import { HiLocationMarker, HiMail } from "react-icons/hi"
import FormSubmit from "../../Components/Body/FormSubmit/FormSubmit"
import Whatsapp from "../../Components/Body/Whatsapp/Whatsapp"
import Footer from "../../Components/Footer/Footer"
import Navbar from "../../Components/Navbar/Navbar"
import "./ContactUs.scss"

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Navbar />
      <Whatsapp />
      <div className="margin">
        <div className="contactinfo">
          <h1>CONTACT INFORMATION</h1>
          <hr />
          <p className="sus">
            Fill up the form and our Team will get back to you within 24 hours.
          </p>
          <div className="condet">
            <p>
              <BsFillTelephoneFill color="#fa949d" /> +91 989479924
            </p>
            <p>
              <HiMail color="#fa949d" /> info@manasampada.in
            </p>
            <p>
              <HiLocationMarker color="#fa949d" /> 2-132/4B, Engineers Enclave
              road, Chandanagar, Hyderabad, TELANGANA - 500050, INDIA
            </p>
          </div>
        </div>
        <div className="formm">
          <FormSubmit title="SEND US A MESSAGE" />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ContactUs
