import React from "react"
import "./Whatsapp.scss"
import whatsapp from "../../../assets/whatsapp.png"

const Whatsapp = () => {
  return (
    <div className="whatsapp">
      <a
        href="https://web.whatsapp.com/send/?phone=919849479924&text&type=phone_number&app_absent=0"
        target="blank"
        className="desk"
      >
        <img src={whatsapp} alt="whatsapp" />
      </a>
      <a href="https://wa.me/+919849479924" target="blank" className="mobi">
        <img src={whatsapp} alt="whatsapp" />
      </a>
    </div>
  )
}

export default Whatsapp
