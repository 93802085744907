import React from "react"
import Imageslider from "../../../Components/Imageslider/Imageslider"
import Navbar from "../../../Components/Navbar/Navbar"
import { saivigneshwaraimages } from "../../../Components/Body/ProjectProfile/images"
import Footer from "../../../Components/Footer/Footer"
import Whatsapp from "../../../Components/Body/Whatsapp/Whatsapp"

const Vigneshwaragallery = () => {
  return (
    <>
      <Navbar />
      <Whatsapp />
      <div className="ongoing">
        <h1 className="title">Sai Vigneshwara Project</h1>
        <hr></hr>
        <Imageslider images={[...saivigneshwaraimages]} />
      </div>
      <Footer />
    </>
  )
}

export default Vigneshwaragallery
