import React, { useEffect } from "react"
import Navbar from "../../Components/Navbar/Navbar"
import "./WhyMsr.scss"
import founder from "../../../src/assets/msrmdimg.jpeg"
import Footer from "../../Components/Footer/Footer"
import { FaRegEye } from "react-icons/fa";
import { FiTarget } from "react-icons/fi";
import FormSubmit from "../../Components/Body/FormSubmit/FormSubmit"

const WhyMsr = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <div className="aboutus">
        <div className="founderpic">
          <img src={founder} alt="founder" />
        </div>
        <div className="aboutmana">
          <h1>About Manasampada</h1>
          <p>
            Dear valued customers,
            <br />
            <br /> It gives me great pleasure to introduce you to Manasampada
            Realtors, a leading real estate company committed to providing
            quality living spaces in Hyderabad. At Manasampada Realtors, we
            believe that a home is more than just a place to live. It's a space
            where memories are made, dreams are realized, and lives are
            transformed.
            <br />
            <br /> We are proud to have completed successful projects like Sai
            Vigneshwara, LG-4, and LG-3, which have not only garnered a lot of
            appreciation but have also contributed to creating a better living
            environment for our customers.
            <br />
            <br /> We are excited to announce our ongoing projects, Lotus Garden
            Premium and Green Meadows, which are designed to cater to the
            diverse needs of our customers. Lotus Garden Premium offers
            luxurious plots with world-class amenities, while Green Meadows
            promises to provide a serene and tranquil living experience amidst
            nature.
            <br />
            <br /> At Manasampada Realtors, we believe in creating homes that
            reflect our customers' personalities, aspirations, and values. We
            are committed to providing the best value for money, uncompromising
            quality, and exceptional customer service.
            <br />
            <br /> We invite you to join our growing family of satisfied
            customers and be a part of the Manasampada Realtors community. Thank
            you for considering us as your trusted real estate partner. Warm
            Regards, MSR Founder, Manasampada Realtors
          </p>
          <div className="blocks">
            <div className="vision">
              <h1><FaRegEye size={30}/> Vision</h1>
              <p>
                Our vision is to create a better tomorrow for all individuals,
                families, and communities. To redefine lifestyle standards
                through building world-class real estate solutions.
              </p>
            </div>
            <div className="mission">
              <h1><FiTarget /> Mission</h1> 
              <p>
                Our mission is to engage in issues that matter to the people
                through an uncompromising commitment to creating an outstanding
                living environment.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default WhyMsr
