import React, { useEffect } from "react"
import Footer from "../../../Components/Footer/Footer"
import Navbar from "../../../Components/Navbar/Navbar"
import "./vigneshwara.scss"

import lotus from "../../../assets/vigneshlogo.png"
import plot from "../../../assets/projects/saivigneshwara/7.jpg"
import { GrLocation } from "react-icons/gr"

import roads from "../../../assets/roads.png"
import vastu from "../../../assets/vastu.png"
import lights from "../../../assets/lights.png"
import security from "../../../assets/watertank.png"
import water from "../../../assets/watertank.png"
import play from "../../../assets/playground.png"
import drinage from "../../../assets/drinage.png"
import electricity from "../../../assets/electricity.png"
import FormSubmit from "../../../Components/Body/FormSubmit/FormSubmit"

import image from "../../../assets/projects/saivigneshwara/1.jpg"
import image1 from "../../../assets/projects/saivigneshwara/2.jpg"
import image2 from "../../../assets/projects/saivigneshwara/3.jpg"
import image3 from "../../../assets/projects/saivigneshwara/4.jpg"
import image4 from "../../../assets/projects/saivigneshwara/5.jpg"
import image5 from "../../../assets/projects/saivigneshwara/6.jpg"
import image6 from "../../../assets/projects/saivigneshwara/7.jpg"
import image7 from "../../../assets/projects/saivigneshwara/8.jpg"
import image8 from "../../../assets/projects/saivigneshwara/9.jpg"
import image9 from "../../../assets/plot1 (6).jpeg"
import image10 from "../../../assets/plot1 (7).jpeg"
import Whatsapp from "../../../Components/Body/Whatsapp/Whatsapp"
import Imageslider from "../../../Components/Imageslider/Imageslider"

const Projects = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Navbar />
      <Whatsapp />
      <div className="projectFull">
        <div className="projectIntro">
          <img src={lotus} alt="project logo" className="plotlogo1" />
          <img src={plot} alt="project logo" className="plotimg1" />
        </div>
        <div className="projectInfo1">
          <div className="left">
            <img src={lotus} alt="lotus" />
          </div>
          <div className="right">
            <h1>Sai Vigneshwara</h1>
            <p>
              <GrLocation /> Lakdaram
            </p>
            <p>
              Mana Sampadha Realtors are launching a new venture "Sai
              Vigneshwara" at Lakdaram, Shadnagar after successful completion of
              thier maiden venture Vedika phase - 1 & 2 and Lotus Gardens -
              1,2,3 & 4 all are final approved layouts.
            </p>
            <p>
              Sai Vigneshwara directly connects to NH Mumbai. It is situated in
              Lakdaram Village, Patancheru Mandal, Near to GITAM University and
              IIT Kandi. It is situated in the industrial hub surrounded by
              Medical & Eng. Colleges and is near to Gadchibowli & Kollur IT
              SEZ.
            </p>
            <p>
              Sai Vigneshwara is a well designed layout with vaastu compliant,
              with plots in sizes of 167 to 400 sq.yds., with 100' ft, 40' ft,
              30' ft cement concrete roads and all the required facilities as
              per HMDA.
            </p>
          </div>
          {/* <div className="projectDetail">
            <div></div>
          </div> */}
        </div>
        <div className="salient1">
          <h1>Amenities</h1>
          <div className="salienticons1">
            <div className="icon">
              <img src={roads} alt="road" />
              <h4>CEMENT CONCRETE ROADS</h4>
            </div>
            <div className="icon">
              <img src={vastu} alt="vastu" />
              <h4>100% VASTHU</h4>
            </div>
            <div className="icon">
              <img src={lights} alt="road" />
              <h4>STREET LIGHTING</h4>
            </div>
            <div className="icon">
              <img src={security} alt="road" />
              <h4>24/7 SECURITY</h4>
            </div>
          </div>
          <div className="salienticons1">
            <div className="icon">
              <img src={water} alt="road" />
              <h4>DEDICATEED WATER TANK</h4>
            </div>
            <div className="icon">
              <img src={electricity} alt="road" />
              <h4>ELECTRICITY</h4>
            </div>
            <div className="icon">
              <img src={play} alt="road" />
              <h4>CHILDREN'S PLAY AREA</h4>
            </div>
            <div className="icon">
              <img src={drinage} alt="road" />
              <h4>DRAINAGE</h4>
            </div>
          </div>
        </div>
        <Imageslider images={[image, image1, image2, image3, image4, image5]} />
      </div>
      <FormSubmit />
      <Footer />
    </div>
  )
}

export default Projects
