import React from "react"
import { HiLocationMarker, HiMail } from "react-icons/hi"
import { BsFillTelephoneFill } from "react-icons/bs"
import { AiFillFacebook } from "react-icons/ai"
import { AiFillInstagram } from "react-icons/ai"
import { AiFillTwitterSquare } from "react-icons/ai"
import logo from "../../assets/msrlogofinal.svg"
import Iframe from "react-iframe"
import "./Footer.scss"

const Footer = () => {
  return (
    <div className="footer">
      <div className="foot-top">
        <div className="foot-actions">
          <section className="foot-img">
            <div className="foot-logo">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="250 400 1702 1080"
              >
                <defs>
                  <style
                    dangerouslySetInnerHTML={{
                      __html:
                        ".cls-1{font-size:38.08px;font-family:KelveticaNobis, Kelvetica Nobis;}.cls-1,.cls-10{fill:#1f2f75;}.cls-2{letter-spacing:-0.01em;}.cls-3{letter-spacing:-0.01em;}.cls-4{letter-spacing:0em;}.cls-5{letter-spacing:0em;}.cls-6{font-size:57.97px;}.cls-6,.cls-8{fill:#f15b22;}.cls-11,.cls-6{font-family:Karmella-Regular, Karmella;}.cls-11,.cls-7{fill:#636464;}.cls-9{fill:#288b3e;}.cls-11{font-size:38.94px;}",
                    }}
                  />
                </defs>
                <text className="cls-6" transform="translate(638.55 496.3)">
                  M
                </text>
                <text className="cls-6" transform="translate(648.23 560.96)">
                  S
                </text>
                <text className="cls-6" transform="translate(644.49 625.62)">
                  R
                </text>
                <path
                  className="cls-7"
                  d="M485.3,500.56v-27c-14.51.62-21.76,7.73-21.76,16.43v10.6Z"
                />
                <path
                  className="cls-7"
                  d="M490.32,500.56h21.77V490c0-8.7-9.68-15.81-21.77-16.43Z"
                />
                <rect
                  className="cls-7"
                  x="490.32"
                  y="504.35"
                  width="21.76"
                  height="20.71"
                />
                <rect
                  className="cls-7"
                  x="463.54"
                  y="504.35"
                  width="21.76"
                  height="20.71"
                />
                <path
                  className="cls-8"
                  d="M393.92,610.13V525.72l-15.24,9.84v90.25H603.53V522.25l-15.24,13.31v74.57Z"
                />
                <path
                  className="cls-8"
                  d="M588.29,412.85v85l15.24-10.42V397.18H378.68v103l15.24-12.74V412.85Z"
                />
                <polygon
                  className="cls-9"
                  points="491 607.36 421.46 526.67 438.71 511.38 493.55 574.92 611.58 474.12 626.46 491.64 491 607.36"
                />
                <polygon
                  className="cls-10"
                  points="492.07 415.06 561.61 495.74 544.36 511.03 489.52 447.49 371.49 548.3 356.61 530.78 492.07 415.06"
                />
                <text className="cls-11" transform="translate(695.79 496.28)">
                  ana
                </text>
                <text className="cls-11" transform="translate(695.79 561.72)">
                  ampada
                </text>
                <text className="cls-11" transform="translate(695.79 626.07)">
                  ealtors
                </text>
              </svg>
            </div>
          </section>
          <section className="foot-contact">
            <h4>CONTACT US</h4>
            <div className="address">
              <div className="icon1">
                <HiLocationMarker size={17} color="white" />
              </div>
              <div className="detail">
                <h6>ADDRESS</h6>
                <p>
                  2-132/4B, Engineers Enclave road, Chandanagar, Hyderabad,
                  TELANGANA - 500050, INDIA
                </p>
              </div>
            </div>
            <div className="mobile">
              <div className="icon2">
                <BsFillTelephoneFill size={15} color="white" />
              </div>
              <div className="detail2">
                <h6>MOBILE</h6>
                <p className="cellphone">+91 989479924</p>
                <p className="cellphone">+91 6302266315</p>
              </div>
            </div>
            <div className="address">
              <div className="icon3">
                <HiMail size={18} color="white" />
              </div>
              <div className="detail">
                <h6>E-MAIL</h6>
                <p>info@manasampada.in</p>
              </div>
            </div>
          </section>
          <section className="foot-projects">
            <h4>PROJECTS</h4>
            <h6>LG Premium</h6>
            <h6>LG 3</h6>
            <h6>LG 4</h6>
            <h6>Sai Vigneshwara</h6>
            <h6>Green Meadows</h6>
          </section>
          <section className="foot-location">
            <h4>LOCATION</h4>
            <Iframe
              url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d951.3194719612496!2d78.3320088291609!3d17.494243278326017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb937b6453073d%3A0xfba2dfaa7e7fb86a!2sMSR%20Realtors!5e0!3m2!1sen!2sin!4v1672581676309!5m2!1sen!2sin"
              width="300px"
              height="250px"
              frameBorder={0}
              scrolling="yes"
            />
          </section>
        </div>
      </div>
      <div className="foot-bottom">
        <div className="foot-bottom-left">
          <p className="gold">Privacy Policy | Disclaimer</p>
          <p>2023 © Copyright MSR Realtors</p>
        </div>
        <div className="foot-bottom-right">
          <AiFillFacebook
            size={20}
            style={{ marginRight: "5px", cursor: "pointer" }}
          />
          <AiFillInstagram
            size={20}
            style={{ marginRight: "5px", cursor: "pointer" }}
          />
          <AiFillTwitterSquare size={20} style={{ cursor: "pointer" }} />
        </div>
      </div>
      {/* <a
        href="https:wa.me/917207657614"
        class="float"
        target="_blank"
      >
        <i className="fa fa-whatsapp my-float">
        </i>
      </a> */}
    </div>
  )
}

export default Footer
