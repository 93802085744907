import React from "react"
import { useState, useCallback } from "react"
import ImageViewer from "react-simple-image-viewer"
import "./Imageslider.scss"

const Imageslider = (props) => {
  const [currentImage, setCurrentImage] = useState(0)
  const [isViewerOpen, setIsViewerOpen] = useState(false)
  const images = props.images

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index)
    setIsViewerOpen(true)
  }, [])

  const closeImageViewer = () => {
    setCurrentImage(0)
    setIsViewerOpen(false)
  }

  return (
    <div className="imageshow">
      <div className="hotelImages">
        {images.map((src, index) => (
          <div className="hotelImgWrapper">
            <img
              src={src}
              onClick={() => openImageViewer(index)}
              key={index}
              className="hotelImg"
              alt="plotimg"
            />
          </div>
        ))}
      </div>

      {isViewerOpen && (
        <div className="viewer">
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            disableScroll={true}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        </div>
      )}
    </div>
  )
}

export default Imageslider
