import React, { useRef } from "react"
import emailjs from "@emailjs/browser"
import "./FormSubmit.scss"
import plane from "../../../assets/paperplane.png"

//ref={form} onSubmit={sendEmail} className="form"

const FormSubmit = (props) => {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        "service_pxkkygr",
        "template_blgzw84",
        form.current,
        "gd-Io5nr7nE2Q2Zos"
      )
      .then(
        (result) => {
          alert("Your Request is sent Succesfully!!")
        },
        (error) => {
          alert.log(error.text)
        }
      )
  }

  return (
    <div className="form-main">
      {props.left && (
        <div className="leftt">
          <img src={plane} alt="plane" />
        </div>
      )}
      <div className="rightt">
        <h1>{props.title}</h1>
        <p>FILL THE DETAILS TO BOOK A FREE SITE VISIT</p>
        <div className="box">
          <form
            className="form"
            target="_blank"
            action="https://formsubmit.co/info@manasampada.in"
            method="POST"
          >
            <div className="flexhere">
              <input
                id="name"
                type="text"
                name="name"
                placeholder="Name*"
                required
              />
              <input id="email" type="email" name="email" placeholder="Email" />
            </div>
            <input
              id="tele"
              type="tel"
              name="tele"
              placeholder="Phone No*"
              required
            />
            <br />
            <select name="project" id="project">
              <option value="" defaultValue={true}>
                Select a Project
              </option>
              <option value="Lotus">Lotus Garden Premium</option>
              <option value="Lotus">LG-3</option>
              <option value="Lotus">LG-4</option>
              <option value="Lotus">Green Medows</option>
              <option value="Lotus">Sai Vigneshwara</option>
            </select>
            <textarea
              id="msg"
              placeholder="Type your Message here..."
              name="msg"
            ></textarea>
            <br />
            <p>
              <span className="bold">Disclaimer:</span> I authorize MVR Realtors
              and it's representatives to call, SMS, Email or WhatsApp me about
              it's products and offers.
            </p>
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default FormSubmit
