import "./App.css"
import construction from "../src/assets/underConstruction.jpg"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Home from "./Screens/Home/Home"
import Projects from "./Screens/Projects/Projects"
import GreenMedow from "./Screens/Projects/GreenMedow/GreenMedow"
import Lotus3 from "./Screens/Projects/Lotus3/Lotus3"
import Lotus4 from "./Screens/Projects/Lotus4/Lotus4"
import Vigneshwara from "./Screens/Projects/Vigneshwara/Vigneshwara"
import Ongoing from "./Screens/Projects/Ongoing/Ongoing"
import WhyMsr from "./Screens/WhyMsr/WhyMsr"
import Gallery from "./Screens/Gallery/Gallery"
import ContactUs from "./Screens/ContactUs/ContactUs"
import { ChakraProvider } from "@chakra-ui/react"
import Completed from "./Screens/Projects/Completed/Completed"
import Lgpremiumgallery from "./Screens/Gallery/Vigneshwaragallery/Vigneshwaragallery"
import Greenmedowgallery from "./Screens/Gallery/Greenmedowgallery/Greenmedowgallery"
import Lotus3gallery from "./Screens/Gallery/Lotus3gallery/Lotus3gallery"
import Lotus4gallery from "./Screens/Gallery/Lotus4gallery/Lotus4gallery"
import Vigneshwaragallery from "./Screens/Gallery/Vigneshwaragallery/Vigneshwaragallery"


function App() {
  return (
    <Router>
      <ChakraProvider resetCSS={false}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/lotuspremium" element={<Projects />} />
          <Route path="/greenmedows" element={<GreenMedow />} />
          <Route path="/lotus3" element={<Lotus3 />} />
          <Route path="/lotus4" element={<Lotus4 />} />
          <Route path="/vigneshwara" element={<Vigneshwara />} />
          <Route path="/ongoing" element={<Ongoing />} />
          <Route path="/completed" element={<Completed />} />
          <Route path="/why_msr" element={<WhyMsr />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/contact_us" element={<ContactUs />} />

          <Route path="/lgprmgallery" element={<Lgpremiumgallery />} />
          <Route path="/greenmedgallery" element={<Greenmedowgallery />} />
          <Route path="/lotus3gallery" element={<Lotus3gallery />} />
          <Route path="/lotus4gallery" element={<Lotus4gallery />} />
          <Route path="/vigneshwaragallery" element={<Vigneshwaragallery />} />
        </Routes>
      </ChakraProvider>
    </Router>
  )
}

export default App
