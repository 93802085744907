import React from "react"
import "./Testimonial.scss"
import { ImQuotesLeft } from "react-icons/im"
import testimg from "../../../assets/testiPic1.jpg"
import testimg1 from "../../../assets/testiPic2.jpg"

const Testimonial = () => {
  return (
    <div className="testimonial">
      <div className="testLeft">
        <div className="testiImg">
          <img src={testimg} alt="testimonial pic" />
        </div>
        <div className="testiDetail">
          <div className="testitop">
            <ImQuotesLeft style={{ color: "#2b6cb0" }}  size={25}  />
            <p className="texti">
              First of all, I am very much happy to be part of MSR family. I
              have never seen such type of Higher Management and Staff because
              they are very helpful, nice coordination and timely response.
              Kudos to MSR Realtors…
            </p>
          </div>
          <div className="testibottom">
            <h4 className="name">Manish Vemuri</h4>
            <p className="role">IT Analyst - CISCO</p>
          </div>
        </div>
      </div>
      <div className="testRight">
        <div className="testiImg">
          <img src={testimg1} alt="testimonial pic" />
        </div>
        <div className="testiDetail">
          <div className="testitop">
            {" "}
            <ImQuotesLeft style={{ color: "#2b6cb0" }} size={25} />
            <p className="texti">
              I am fully satisfied with MSR Realtors pvt limited. Went to this
              organization for finding a Plot with an affordable price. The
              staff at MSR are so friendly and cooperative. Thank you so much to
              MSR.
            </p>
          </div>
          <div className="testibottom">
            <h4 className="name">Sangeeta P</h4>
            <p className="role">Architect</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonial
