import React, { useEffect, useState } from "react"
import { BsFillTelephoneFill } from "react-icons/bs"
import { NavLink } from "react-router-dom"
import logo from "../../assets/msrlogo.png"
import "./Navbar.scss"

const Navbar = (props) => {
  const [shows, setShows] = useState(false)

  const showFunc = () => {
    setShows(true)
  }

  const showreFunc = () => {
    setShows(false)
  }

  useEffect(
    function () {
      const x = document.querySelector(".sidebarmobile")
      props.show ? (x.style.width = "min(70vw, 250px)") : (x.style.width = 0)
    },
    [props.show]
  )

  return (
    <>
      <div className="navbar" style={props.style}>
        <section className="logo">
          <NavLink to="/">
            <svg
              id="Layer_1"
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="150 0 1202 1080"
            >
              <defs>
                <style
                  dangerouslySetInnerHTML={{
                    __html:
                      ".cls-1{font-size:38.08px;font-family:KelveticaNobis, Kelvetica Nobis;}.cls-1,.cls-10{fill:#1f2f75;}.cls-2{letter-spacing:-0.01em;}.cls-3{letter-spacing:-0.01em;}.cls-4{letter-spacing:0em;}.cls-5{letter-spacing:0em;}.cls-6{font-size:57.97px;}.cls-6,.cls-8{fill:#f15b22;}.cls-11,.cls-6{font-family:Karmella-Regular, Karmella;}.cls-11,.cls-7{fill:#636464;}.cls-9{fill:#288b3e;}.cls-11{font-size:38.94px;}",
                  }}
                />
              </defs>
              <text className="cls-1" transform="translate(375.43 674.36)">
                <tspan className="cls-2">L</tspan>
                <tspan x="20.97" y={0}>
                  eading
                </tspan>
                <tspan className="cls-3" x="140.54" y={0}>
                  I
                </tspan>
                <tspan className="cls-4" x="155.1" y={0}>
                  n Right Direction
                </tspan>
              </text>
              <text className="cls-6" transform="translate(638.55 496.3)">
                M
              </text>
              <text className="cls-6" transform="translate(648.23 560.96)">
                S
              </text>
              <text className="cls-6" transform="translate(644.49 625.62)">
                R
              </text>
              <path
                className="cls-7"
                d="M485.3,500.56v-27c-14.51.62-21.76,7.73-21.76,16.43v10.6Z"
              />
              <path
                className="cls-7"
                d="M490.32,500.56h21.77V490c0-8.7-9.68-15.81-21.77-16.43Z"
              />
              <rect
                className="cls-7"
                x="490.32"
                y="504.35"
                width="21.76"
                height="20.71"
              />
              <rect
                className="cls-7"
                x="463.54"
                y="504.35"
                width="21.76"
                height="20.71"
              />
              <path
                className="cls-8"
                d="M393.92,610.13V525.72l-15.24,9.84v90.25H603.53V522.25l-15.24,13.31v74.57Z"
              />
              <path
                className="cls-8"
                d="M588.29,412.85v85l15.24-10.42V397.18H378.68v103l15.24-12.74V412.85Z"
              />
              <polygon
                className="cls-9"
                points="491 607.36 421.46 526.67 438.71 511.38 493.55 574.92 611.58 474.12 626.46 491.64 491 607.36"
              />
              <polygon
                className="cls-10"
                points="492.07 415.06 561.61 495.74 544.36 511.03 489.52 447.49 371.49 548.3 356.61 530.78 492.07 415.06"
              />
              <text className="cls-11" transform="translate(695.79 496.28)">
                ana
              </text>
              <text className="cls-11" transform="translate(695.79 561.72)">
                ampada
              </text>
              <text className="cls-11" transform="translate(695.79 626.07)">
                ealtors
              </text>
            </svg>
          </NavLink>
        </section>
        <section className="actions">
          <ul>
            <li>
              <NavLink
                to="/"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                HOME
              </NavLink>
            </li>
            <li onMouseOver={showFunc} onMouseLeave={showreFunc}>
              PROJECTS
              {shows && (
                <>
                  <div className="projectsoptions">
                    <NavLink
                      to="/ongoing"
                      target="_blank"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <p>Ongoing Projects</p>
                    </NavLink>
                    <NavLink
                      to="/completed"
                      target="_blank"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <p>Completed Projects</p>
                    </NavLink>
                  </div>
                </>
              )}
            </li>
            <li>
              <NavLink
                to="/why_msr"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                ABOUT US
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/gallery"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                MEDIA
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/contact_us"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                CONTACT US
              </NavLink>
            </li>
            <a href="tel:+919849479924">
              <button className="but">
                <BsFillTelephoneFill size={13} /> +91 9849479924
              </button>
            </a>
          </ul>
        </section>
      </div>

      <nav className="navbar-mobile">
        <NavLink to="/">
          <img src={logo} alt="logo" className="logoo" />
        </NavLink>
        <a href="tel:+919849479924">
          <button className="butt">
            <BsFillTelephoneFill size={13} color="#288b3e" /> CALL US
          </button>
        </a>
        <div className="burger" onClick={props.toggle}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
      </nav>
      <div className="sidebarmobile">
        <div className="side-listmobile">
          <NavLink to="/">
            <h1>
              <span className="list-letter">H</span>OME
            </h1>
          </NavLink>
          {/* <NavLink to="/about">
            <h1>
              <span className="list-letter">A</span>BOUT
            </h1>
            <hr></hr>
          </NavLink> */}
          <NavLink to="/why_msr">
            <h1>
              <span className="list-letter">A</span>BOUT US
            </h1>
            <hr></hr>
          </NavLink>
          <NavLink to="/gallery">
            <h1>
              <span className="list-letter">M</span>EDIA
            </h1>
            <hr></hr>
          </NavLink>
          <NavLink to="/contact_us">
            <h1>
              <span className="list-letter">C</span>ONTACT US
            </h1>
            <hr></hr>
          </NavLink>
        </div>
      </div>
    </>
  )
}

export default Navbar
