import React from "react"
import "./Slider.css"
import { Carousel } from "react-responsive-carousel"
import styles from "react-responsive-carousel/lib/styles/carousel.min.css"
import image from "../../../assets/msrPlotImg1.jpeg"
import image1 from "../../../assets/msrPlotImg2.jpeg"
import image2 from "../../../assets/msrPlotImg3.jpeg"
// import image3 from "../../../assets/msrMD.jpeg"
import image4 from "../../../assets/plot1 (1).jpeg"
import image5 from "../../../assets/plot1 (2).jpeg"
import image6 from "../../../assets/plot1 (3).jpeg"
import image7 from "../../../assets/plot1 (4).jpeg" 
import image8 from "../../../assets/plot1 (5).jpeg"
import image9 from "../../../assets/plot1 (6).jpeg"
import image10 from "../../../assets/plot1 (7).jpeg"

const Slider = () => { 
  return (
    <div className="slider">
      <Carousel
        autoPlay={true}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        showArrows={true}
        infiniteLoop={true}
        dynamicHeight={true}
        stopOnHover={true}
      >
        <div>
          <img src={image} className="img1" alt="plot" />
        </div>
        <div>
          <img src={image1} className="img2" alt="plot" />
        </div>
        <div>
          <img src={image2} className="img3" alt="plot" />
        </div>
        <div>
          <img src={image4} className="img3" alt="plot" />
        </div>
        <div>
          <img src={image5} className="img3" alt="plot" />
        </div>
        <div>
          <img src={image6} className="img3" alt="plot" />
        </div>
        <div>
          <img src={image7} className="img3" alt="plot" />
        </div>
        <div>
          <img src={image8} className="img3" alt="plot" />
        </div>
        <div>
          <img src={image9} className="img3" alt="plot" />
        </div>
        <div>
          <img src={image10} className="img3" alt="plot" />
        </div>
      </Carousel>
    </div>
  )
}

export default Slider
