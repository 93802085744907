import React, { Component } from "react"
import { NavLink } from "react-router-dom";
import "./Welcome.css"

const textArray = ["మనసంపద", "Mana Sampada"];

class Welcome extends Component {
  constructor() {
    super();
    this.state = { textIdx: 0 };
  }

  componentDidMount() {
    this.timeout = setInterval(() => {
      let currentIdx = this.state.textIdx;
      this.setState({ textIdx: currentIdx + 1 });
    }, 2000);
  }

  componentDidUnmount() {
    clearInterval(this.timeout); 
  }

  render(){
    let textThatChanges = textArray[this.state.textIdx % textArray.length] 
    return (
      <div className="welcome">
        <div className="welcome-cta">
        <h4 className="welcome-para">Welcome to</h4>
        <h1 className="welcome-title" id="words">{textThatChanges}</h1>
        <hr></hr>
        <p>Are you looking for the perfect plot of land to call your own?</p>
        <NavLink to="/ongoing" target="_blank">
        <button>OUR PROJECTS</button>
        </NavLink>
        </div>
      </div>
  )
  }
}


export default Welcome
// మనసంపద రియల్టర్స్