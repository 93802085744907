import React from "react"
import "./CompanyProfile.scss"
import whymsrimg from "../../../assets/whymsr.png"

const CompanyProfile = () => {
  return (
    <div className="profile">
      <div className="whymsr">
        <div className="whymsr-left">
          <img src={whymsrimg} className="whymsr-img" />
        </div>
        <div className="whymsr-right">
          <h1>
            WHY <span className="msr-title">MSR</span>
          </h1>
          <p>
            MANA SAMPADA REALTORS PVT LTD.,over the years has evolved into a
            Re-puted & Reliable group that has sought to Thrive year on year
            through its Focused Approach, Continuous Efforts, Timely Execution &
            Delivery of our Projects as Promised to our Clients & Stake Holders.
            <br></br>
            Our Idea is to Churn out Abodes & Landmarks of Highest Quality with
            Thoughtful & Practical Communities & Spaces that People Enjoy
            through their Lives.
          </p>
        </div>
      </div>
      <div className="stats">
        <div>
          <h1>03</h1>
          <p>COMPLETED PROJECTS</p>
        </div>
        <div>
          <h1>1,200+</h1>
          <p>PLOTS DELIVERED</p>
        </div>
        <div>
          <h1>02</h1>
          <p>UPCOMING PROJECTS</p>
        </div>
        <div>
          <h1>10+</h1>
          <p>YEARS PROFICIENCY</p>
        </div>
      </div>
    </div>
  )
}

export default CompanyProfile
